/* eslint-disable no-fallthrough */
import { AnyAction } from "redux";
import {
  FETCH_PRODUCTS_BY_TYPE_ERROR,
  FETCH_PRODUCTS_BY_TYPE_SUCCESS,
  REQUEST_FETCH_PRODUCTS_BY_TYPE,
} from "redux/actions/products/byType";

const defaultState = {
  data: {
    split: {
      totalPages: 0,
      totalRecords: 0,
      products: null,
      error: null,
      loading: false,
    },
    skyAir: {
      totalPages: 0,
      totalRecords: 0,
      products: null,
      error: null,
      loading: false,
    },
    multiSplit: {
      totalPages: 0,
      totalRecords: 0,
      products: null,
      error: null,
      loading: false,
    },
    airPurifier: {
      totalPages: 0,
      totalRecords: 0,
      products: null,
      error: null,
      loading: false,
    },
    comboTietKiem: {
      totalPages: 0,
      totalRecords: 0,
      products: null,
      error: null,
      loading: false,
    },
    homeFilter: {},
  },
};

export default (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST_FETCH_PRODUCTS_BY_TYPE:
      const { slug } = action.filters;
      switch (slug) {
        case "loai-2-dan-roi":
          return {
            ...state,
            data: {
              ...state.data,
              split: {
                ...state.data.split,
                error: null,
                loading: true,
              },
            },
          };
        case "may-loc-khong-khi":
          return {
            ...state,
            data: {
              ...state.data,
              airPurifier: {
                ...state.data.airPurifier,
                error: null,
                loading: true,
              },
            },
          };
        case "loai-multi":
        case "loai-multi-s":
          return {
            ...state,
            data: {
              ...state.data,
              multiSplit: {
                ...state.data.multiSplit,
                error: null,
                loading: true,
              },
            },
          };
        case "sky-air":
          return {
            ...state,
            data: {
              ...state.data,
              skyAir: {
                ...state.data.skyAir,
                error: null,
                loading: true,
              },
            },
          };
        default:
          break;
      }

    case FETCH_PRODUCTS_BY_TYPE_SUCCESS: {
      switch (action.slug) {
        case "loai-2-dan-roi":
          return {
            ...state,
            data: {
              ...state.data,
              split: {
                totalPages: action.byType.meta
                  ? action.byType.meta.totalPages
                  : 0,
                totalRecords: action.byType.meta
                  ? action.byType.meta.totalRecord
                  : 0,
                products: action.byType.data,
                error: null,
                loading: false,
              },
              homeFilter: action.homeFilter,
              seo: action.byType.seo,
            },
          };
        case "may-loc-khong-khi":
          return {
            ...state,
            data: {
              ...state.data,
              airPurifier: {
                totalPages: action.byType.meta
                  ? action.byType.meta.totalPages
                  : 0,
                totalRecords: action.byType.meta
                  ? action.byType.meta.totalRecord
                  : 0,
                products: action.byType.data,
                error: null,
                loading: false,
              },
              seo: action.byType.seo,
            },
          };
        case "combo-tiet-kiem":
          return {
            ...state,
            data: {
              ...state.data,
              comboTietKiem: {
                totalPages: action.byType.meta
                  ? action.byType.meta.totalPages
                  : 0,
                totalRecords: action.byType.meta
                  ? action.byType.meta.totalRecord
                  : 0,
                products: action.byType.data,
                error: null,
                loading: false,
              },
              seo: action.byType.seo,
            },
          };
        case "loai-multi":
        case "loai-multi-s":
          return {
            ...state,
            data: {
              ...state.data,
              multiSplit: {
                totalPages: action.byType.meta
                  ? action.byType.meta.totalPages
                  : 0,
                totalRecords: action.byType.meta
                  ? action.byType.meta.totalRecord
                  : 0,
                products: action.byType.data,
                error: null,
                loading: false,
              },
              seo: action.byType.seo,
            },
          };
        case "sky-air":
          return {
            ...state,
            data: {
              ...state.data,
              skyAir: {
                totalPages: action.byType.meta
                  ? action.byType.meta.totalPages
                  : 0,
                totalRecords: action.byType.meta
                  ? action.byType.meta.totalRecord
                  : 0,
                products: action.byType.data,
                error: null,
                loading: false,
              },
              homeFilter: action.homeFilter,
              seo: action.byType.seo,
            },
          };
        default:
          break;
      }
    }
    case FETCH_PRODUCTS_BY_TYPE_ERROR:
      switch (action.slug) {
        case "loai-2-dan-roi":
          return {
            ...state,
            data: {
              ...state.data,
              split: {
                ...state.data.split,
                error: action.error,
                loading: false,
              },
            },
          };
        case "may-loc-khong-khi":
          return {
            ...state,
            data: {
              ...state.data,
              airPurifier: {
                ...state.data.airPurifier,
                error: action.error,
                loading: false,
              },
            },
          };
        case "loai-multi":
        case "loai-multi-s":
          return {
            ...state,
            data: {
              ...state.data,
              multiSplit: {
                ...state.data.multiSplit,
                error: action.error,
                loading: false,
              },
            },
          };
        case "sky-air":
          return {
            ...state,
            data: {
              ...state.data,
              skyAir: {
                ...state.data.skyAir,
                error: action.error,
                loading: false,
              },
            },
          };
        default:
          break;
      }
    default:
      return state;
  }
};
